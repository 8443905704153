import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Title from "../components/Title"
import Seo from "../components/Seo"

const About = ({ data }) => {
  const {
    sanityAuthor: { name, bio, image, stack },
  } = data

  return (
    <>
      <Seo title="About" />
      <section className="about-page">
        <div className="section-center about-center">
          <GatsbyImage
            image={getImage(image.asset)}
            // className="project-img"
            alt={name}
            className="about-img-svg"
          />
          <article className="about-text">
            <Title title={name} />

            <p className="about-text">
              {bio.map(item => {
                return (
                  <div key={item._key}>
                    {item.children.map(item2 => {
                      return <div key={item2._key}>{item2.text}</div>
                    })}
                  </div>
                )
              })}
            </p>

            <div className="about-stack">
              {stack.map(item => {
                return <span>{item}</span>
              })}
            </div>
          </article>
        </div>
      </section>
    </>
  )
}

export const query = graphql`
  {
    sanityAuthor {
      name
      stack
      bio {
        children {
          text
        }
      }
      image {
        asset {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 1280
          )
        }
      }
    }
  }
`

export default About
